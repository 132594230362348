import { type AddOnItem, type Item, ItemQuantitySyncGroup } from '@/api/items'
import itemStore, { ItemModel } from '@/catalog/stores/items'
import { type ItemCartContext } from '@/services/cart/types'

/**
 * Gets items JSON data from the API. Repeated lookups are cached.
 */
export const itemsJson = async (
  ...itemIds: number[]
): Promise<{ [itemId: number]: Item }> => {
  const itemJsons = await Promise.all(
    itemIds.map((id) => new Promise(itemStore.getItem(id).fetchJqXhr.then))
  )
  return itemJsons.reduce(
    (obj, item) => {
      obj[item.id] = item
      return obj
    },
    {} as { [key: number]: Item }
  )
}

const itemCartContextCache: { [itemId: number]: ItemCartContext } = {}

const entryFromItem = (item: ItemModel) => ({
  quantitySyncBehavior: item.get('quantitySyncBehavior')!,
  quantitySyncGroup: item.get('quantitySyncGroup')!,
  minQuantity: item.get('minQuantity')!
})

const entryFromAddOnItem = (item: AddOnItem) => ({
  quantitySyncBehavior: item.quantitySyncBehavior,
  // quantitySyncGroup isn't in the data model currently.
  // However, until addresses are extracted from Avetti,
  // this is effectively correct. Once addresses are extracted,
  // envelopes won't be synced anymore and this should become `null`;
  // the quantities of the envelopes themselves (set by the address book app in the cart)
  // will become the source of truth for cart operations, instead of the cart recipient list.
  quantitySyncGroup:
    item.quantitySyncBehavior === 'RECIPIENTS'
      ? ItemQuantitySyncGroup.Recipients
      : null,
  // Assume there's no effective minimum and that it won't be used,
  // because add-on quantities should always be synced, or for extra envelopes, set absolutely.
  minQuantity: 1
})

/**
 * Fetches limited data used for cart operations.
 *
 * Optimizes performance with caching and reuse if existing cached item data.
 * Caching logic is simplified because concurrent cart operations are not expected.
 */
export const itemCartContextData = async (
  itemIds: Set<number>
): Promise<{ [itemId: number]: ItemCartContext }> => {
  const unfetchedItemIds = new Set([...itemIds])
  const data: { [itemId: number]: ItemCartContext } = {}

  /**
   * Adds to shared cache, and if item ID was requested, adds to the local data accumulator.
   */
  const addEntry = (itemId: number, entry: ItemCartContext) => {
    itemCartContextCache[itemId] = entry
    if (unfetchedItemIds.delete(itemId)) {
      data[itemId] = entry
    }
  }

  // Reference precached data first
  itemIds.forEach((itemId) => {
    if (itemCartContextCache[itemId] && unfetchedItemIds.delete(itemId)) {
      data[itemId] = itemCartContextCache[itemId]!
    }
  })

  for (const item of itemStore.models) {
    // Skip work if there's nothing else to fetch
    if (unfetchedItemIds.size === 0) {
      break
    }
    // Skipping non-resolved requests keeps things simple, since no item fetches are expected to be in progress.
    // All item data in the model is assumed to be defined after this.
    if (item.fetchJqXhr.state() != 'resolved') {
      continue
    }

    // Copy data from the main item
    addEntry(item.get('id'), entryFromItem(item))

    /**
     * For each add-on, if it has a defined item that matches an unfetched item ID,
     * add its data into the accumulated data.
     */
    const findAddOnItems = (addOns: { item: AddOnItem | null }[]) => {
      if (unfetchedItemIds.size === 0) {
        return
      }
      addOns.forEach((addOnItem) => {
        if (!addOnItem.item) {
          return
        }

        addEntry(addOnItem.item.id, entryFromAddOnItem(addOnItem.item))
      })
    }
    findAddOnItems(item.get('envelopes')!)
    findAddOnItems(item.get('trims')!)
    findAddOnItems(item.get('formatSubstrates')!)
  }

  // Fetch remaining items directly and in parallel
  if (unfetchedItemIds.size > 0) {
    Object.entries(await itemsJson(...unfetchedItemIds)).forEach(
      ([itemId, { quantitySyncBehavior, quantitySyncGroup, minQuantity }]) => {
        // Only copy the minimal data to keep context tidy and uniform
        data[Number(itemId)] = {
          quantitySyncBehavior,
          quantitySyncGroup,
          minQuantity
        }
      }
    )
  }

  return data
}
