import {
  AddToCartProperties,
  BaseCartItem,
  BillingViewProperties,
  CanceledOrderProperties,
  CanonicalCategory,
  ClickedItemProperties,
  EmailSignedUpLeadGenFailedProperties,
  EmailSignedUpLeadGenProperties,
  EmailSignedUpPopupProperties,
  FavoriteActionProperties,
  FullCartItem,
  FullCartItemFlat,
  LeadCreatedProperties,
  CreatedAccountProperties,
  AjaxErrorProperties,
  OrderCompletedProperties,
  ViewedProductListProperties,
  SearchedProductsProperties,
  SampleKitRequestSubmittedProperties,
  SavedForLaterProperties,
  SearchCategoryProperties,
  SetAddressListErrorOnSuccessProperties,
  ShareProperties,
  ShippingAndPaymentSelectedProperties,
  SignedInProperties,
  ViewedPersonalizeStepProperties,
  ZarazTrackProduct,
  ChangedPasswordErrorProperties,
  CreatedAccountReferrerProperties,
  ChangedEmailProperties,
  ChangedEmailErrorProperties,
  ChangedNameProperties,
  ChangedNameErrorProperties,
  UpscaledImageProperties,
  SavedProofErrorProperties,
  RestoredMismatchedVersionProperties,
  SavedProofOptionsProperties,
  StructuredReturnAddressProperties,
  InvalidStructuredReturnAddressProperties,
  RestoredInvalidAttemptedSkipToOptionStepFlowProperties,
  RevertedToDefaultEnvelopeProperties,
  RestoredSkipToOptionStepFlowProperties,
  SavedProofErrorOnFinishAddOnFlowProperties,
  ZoomedImageProperties,
  AddedPhotoProperties,
  PreviewedPhotoProperties,
  RotatedImageProperties,
  ConfirmedLowDpiWarningProperties,
  RestoredProofErrorProperties,
  LoadedProofFromVersionProperties,
  ClickedProductPageTabProperties,
  ClickedProductPagePopoverProperties,
  OverflowedDedicationTextBoxProperties,
  UnreadZendeskWebWidgetMessagesProperties
} from '@/analytics/types'
import * as gtm from '@/analytics/gtm'
import { GtmProduct } from '@/analytics/gtm'
import * as zaraz from '@/analytics/zaraz'
import { ZarazEcommerceProduct } from '@/analytics/zaraz'
import { resolveAfterTimeout } from '@/utils/promise'
import { track } from '@/analytics/trackAsync'
import { getPriceAtQuantity } from '@/utils/itemUtils'
import { itemsJson } from '@/services/item'
import { Item } from '@/api/items'
import { legacyWoopraKeysToSnake } from '@/analytics/woopra-utils'

import * as pageDataUtils from '@/utils/pageDataUtils'
import { analytics } from '@/analytics/main'

const withTimeout = (...promises: Promise<unknown>[]): Promise<void> =>
  resolveAfterTimeout(Promise.all(promises), 500) as Promise<void>

/**
 * Formats a Category as majorCategory/minorCategory.
 *
 * Many analytics events accept `category` as a single string.
 * This normalizes a (assumed to be canonical) category to use major and minor category
 * for more meaningful analytics. In effect, this will also aggregate multiple distinct
 * categories, which are often mainly split for SEO reasons.
 */
const flattenedCategory = (category: CanonicalCategory): string =>
  [
    category.majorCategory || 'majorNull',
    category.minorCategory || 'minorNull'
  ].join('/')

const storeItemToGtmCartItem = (
  fullItem: Item,
  quantity: number
): GtmProduct => {
  return {
    // code is more meaningful than id with only a single identifier
    id: fullItem.code,
    name: fullItem.pageTitle,
    /** The unit price including the quantity discount */
    price: getPriceAtQuantity(
      fullItem.prices,
      fullItem.priceQuantities,
      quantity
    ),
    quantity,
    brand: fullItem.designerName,
    variant: fullItem.canonicalColor,
    category: flattenedCategory(fullItem.canonicalCategory)
  }
}

const storeItemToZarazCartItem = (
  fullItem: Item,
  quantity: number
): ZarazEcommerceProduct =>
  fullCartItemToZarazProduct({
    ...fullItem,
    price: getPriceAtQuantity(
      fullItem.prices,
      fullItem.priceQuantities,
      quantity
    ),
    quantity
  })

const fullCartItemToFlatten = (item: FullCartItem): FullCartItemFlat => {
  const canonicalCategory = item.canonicalCategory
  return {
    itemId: item.id,
    itemCode: item.code,
    itemPageTitle: item.pageTitle,
    itemPrice: item.price,
    itemDesignerName: item.designerName,
    itemCanonicalColor: item.canonicalColor,
    majorCategory: canonicalCategory?.majorCategory || 'majorNull',
    minorCategory: canonicalCategory?.minorCategory || 'minorNull',
    quantity: item.quantity
  }
}

const fullCartItemToZarazTrackProduct = (
  item: FullCartItem
): ZarazTrackProduct => {
  return {
    id: item.id,
    code: item.code,
    pageTitle: item.pageTitle,
    price: item.price,
    quantity: item.quantity,
    canonicalCategory: {
      majorCategory: item.canonicalCategory.majorCategory,
      minorCategory: item.canonicalCategory.minorCategory
    }
  }
}
const fullCartItemToZarazProduct = (
  item: FullCartItem
): ZarazEcommerceProduct => {
  return {
    product_id: item.id.toString(),
    sku: item.code,
    name: item.pageTitle,
    brand: item.designerName,
    variant: item.canonicalColor,
    price: item.price,
    quantity: item.quantity,
    category: flattenedCategory(item.canonicalCategory)
  }
}

const fullCartItemToGtmProduct = (item: FullCartItem): GtmProduct => {
  return {
    name: item.pageTitle,
    id: item.code,
    price: item.price,
    brand: item.designerName,
    category: flattenedCategory(item.canonicalCategory),
    variant: item.canonicalColor,
    quantity: item.quantity
  }
}

const getGtmMappedItems = async (
  ...items: BaseCartItem[]
): Promise<GtmProduct[]> => {
  const itemIdToItem = await itemsJson(...items.map((i) => i.id))
  return items.map((item) =>
    // Always defined because `items` is used to populate `itemIdToItem`
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    storeItemToGtmCartItem(itemIdToItem[item.id]!, item.quantity)
  )
}

const getZarazMappedItems = async (
  ...items: BaseCartItem[]
): Promise<ZarazEcommerceProduct[]> => {
  const itemIdToItem = await itemsJson(...items.map((i) => i.id))
  return items.map((item) =>
    // Always defined because `items` is used to populate `itemIdToItem`
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    storeItemToZarazCartItem(itemIdToItem[item.id]!, item.quantity)
  )
}

export const trackLeadCreated = async (
  leadCreatedProperties: LeadCreatedProperties
): Promise<void> => {
  return withTimeout(
    // Send event to Google Tag Manager to fire relevant events related to lead creation
    // (e.g. Facebook lead conversion: https://app.asana.com/0/4029883351998/8629183774337).
    // Some events may also fire another more specific GTM event too, but reusing `leadCreated`
    // allows us to consistently fire the same tags for separate actions without reconfiguring GTM.
    gtm.trackEvent('leadCreated'),
    zaraz.track('leadCreated', { email: leadCreatedProperties.email })
  )
}

export const trackSignedUpFromEmailPopup = async (
  emailSignedUpPopupProperties: EmailSignedUpPopupProperties
): Promise<void> => {
  // VWO Custom Goal: ECP Signed Up
  window._vis_opt_goal_conversion?.(208)
  const { ...trackingProperties } = emailSignedUpPopupProperties
  return withTimeout(
    track(
      'Signed up',
      {
        ...trackingProperties,
        category: 'Email Collection Popup',
        label: emailSignedUpPopupProperties.delaySeconds.toString()
      },
      {
        integrations: {
          woopra: true
        }
      }
    ),
    // Send event to Google Tag Manager to fire relevant events
    // at least Facebook conversion: https://app.asana.com/0/4029883351998/8629183774337
    gtm.trackEvent('emailSignedUpPopup'),
    trackLeadCreated(emailSignedUpPopupProperties)
  )
}

export const trackSignedUpForNewsletterFromFooter = async (
  leadCreatedProperties: LeadCreatedProperties
) => {
  return withTimeout(
    track('Signed up', {
      category: 'Footer newsletter signup'
    }),
    trackLeadCreated(leadCreatedProperties)
  )
}

export const trackEmailSignedUpLeadGen = async (
  emailSignedUpLeadGenProperties: EmailSignedUpLeadGenProperties
): Promise<void> => {
  const { ...trackingProperties } = emailSignedUpLeadGenProperties
  return withTimeout(
    track(
      'Code sent',
      {
        ...trackingProperties,
        category: 'Email promotion code',
        label: window.location.href,
        referrer: document.referrer
      },
      { integrations: { woopra: true } }
    ),
    trackLeadCreated(emailSignedUpLeadGenProperties)
  )
}

export const trackEmailSignedUpLeadGenFailed = async (
  emailSignedUpLeadGenFailedProperties: EmailSignedUpLeadGenFailedProperties
): Promise<void> => {
  const { ...trackingProperties } = emailSignedUpLeadGenFailedProperties
  return withTimeout(
    track(
      'No more codes available',
      {
        ...trackingProperties,
        category: 'Email promotion code',
        label: window.location.href,
        referrer: document.referrer
      },
      { integrations: { woopra: true } }
    )
  )
}

export const trackCreatedAccount = async (
  createdAccountProperties: CreatedAccountProperties
): Promise<void> => {
  return withTimeout(
    track(
      'Create Account',
      {
        ...createdAccountProperties,
        category: 'Login Modal',
        label: 'Account Created',
        pageType: 'registration'
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    ),
    gtm.trackEvent('accountCreated', createdAccountProperties),
    zaraz.track('accountCreated', {
      email: createdAccountProperties.email,
      referrerSource:
        createdAccountProperties.referrer === 'other'
          ? createdAccountProperties.referrerOther ||
            createdAccountProperties.referrer
          : createdAccountProperties.referrer,
      method: createdAccountProperties.method
    })
  )
}

export const trackCreatedAccountFailed = async (): Promise<void> => {
  return withTimeout(
    track(
      'Create Account',
      {
        category: 'Login Modal',
        label: 'Error'
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}
export const trackCreatedAccountErrorParsingResponse =
  async (): Promise<void> => {
    return withTimeout(
      track(
        'Create Account',
        {
          category: 'Login Modal',
          label: 'Error Parsing Response'
        },
        {
          // Improve UI performance
          defer: true,
          integrations: {
            woopra: true
          }
        }
      )
    )
  }

export const trackCreatedAccountDuplicateAccountError =
  async (): Promise<void> => {
    return withTimeout(
      track(
        'Create Account',
        {
          category: 'Login Modal',
          label: 'Duplicate Account'
        },
        {
          // Improve UI performance
          defer: true,
          integrations: {
            woopra: true
          }
        }
      )
    )
  }

export const trackCreatedAccountAjaxRpcError = async (
  createdAccountAjaxRpcErrorProperties: AjaxErrorProperties
): Promise<void> => {
  return withTimeout(
    track(
      'Create Account',
      {
        category: 'Login Modal',
        label: `AJAX RPC Error (${createdAccountAjaxRpcErrorProperties.textStatus} / ${createdAccountAjaxRpcErrorProperties.errorThrown})`
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackSignedIn = async (
  signedInProperties: SignedInProperties
): Promise<void> => {
  return withTimeout(
    track(
      'Login',
      {
        category: 'Login Modal',
        label: 'Logged In'
      },
      {
        defer: true, // Improve UI performance
        integrations: {
          woopra: true
        }
      }
    ),
    zaraz.track('signedIn', {
      method: signedInProperties.method
    })
  )
}

export const trackSharedContent = async (
  shareProperties: ShareProperties
): Promise<void> => {
  return withTimeout(
    gtm.trackEvent('_trackSocial', {
      socialNetwork: shareProperties.method,
      socialActionTarget: shareProperties.url
    }),
    zaraz.track('sharedContent', shareProperties)
  )
}
export const trackBillingView = async (
  billingViewProperties: BillingViewProperties
): Promise<void> => {
  return withTimeout(
    // Track billing view in Enhanced Ecommerce
    gtm.trackEvent('checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            // This is the number of the "funnel step" defined in Analytics
            step: 1
          }
        }
      }
    }),
    zaraz.ecommerce('Checkout Started', {
      products: await getZarazMappedItems(...billingViewProperties.items)
    })
  )
}

export const trackShippingAndPaymentSelected = async (
  shippingAndPaymentSelectedProperties: ShippingAndPaymentSelectedProperties
): Promise<void> => {
  return withTimeout(
    track('Selected shipping option', {
      category: 'Checkout',
      label: shippingAndPaymentSelectedProperties.shippingOption
    }),
    track('Selected payment option', {
      category: 'Checkout',
      label:
        'Credit Card: ' + shippingAndPaymentSelectedProperties.creditCardType
    }),
    gtm.trackEvent('checkoutOption', {
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 1,
            option: shippingAndPaymentSelectedProperties.shippingOption
          }
        }
      }
    }),
    // Item data is expected to be cached because it was looked up on page load for Checkout Started.
    // If it wasn't already cached, we would likely exceed the timeout and leave the page before tracking was fired.
    getZarazMappedItems(...shippingAndPaymentSelectedProperties.items).then(
      (mappedProducts) => {
        return Promise.all([
          zaraz.ecommerce('Payment Info Entered', { products: mappedProducts }),
          zaraz.ecommerce('Shipping Info Entered', { products: mappedProducts })
        ])
      }
    )
  )
}

export const trackSampleKitRequestSubmitted = async (
  sampleKitRequestSubmittedProperties: SampleKitRequestSubmittedProperties
): Promise<void> => {
  return withTimeout(
    track(
      'Submitted Form',
      {
        ...sampleKitRequestSubmittedProperties,
        category: 'Generic Sample Kit Request',
        label: window.location.pathname
      },
      {
        integrations: {
          woopra: true
        }
      }
    ),
    gtm.trackEvent('sampleKitRequestSubmitted', {
      ...sampleKitRequestSubmittedProperties
    }),
    trackLeadCreated({ email: sampleKitRequestSubmittedProperties.email })
  )
}

export const trackSetAddressList = async (): Promise<void> => {
  return withTimeout(
    track('Set address list', {
      category: 'Cart',
      label: 'Success',
      nonInteraction: true
    })
  )
}

export const trackSetAddressListError = async (): Promise<void> => {
  return withTimeout(
    track('Set address list', {
      category: 'Cart',
      label: 'Error',
      nonInteraction: true
    })
  )
}

export const trackSetAddressListErrorOnSuccess = async (
  setAddressListErrorOnSuccessProperties: SetAddressListErrorOnSuccessProperties
): Promise<void> => {
  return withTimeout(
    track('Set address list', {
      category: 'Cart',
      label:
        'Error on success' + setAddressListErrorOnSuccessProperties.messages,
      nonInteraction: true
    })
  )
}

export const trackOrderCompleted = async (
  orderCompletedProperties: OrderCompletedProperties
): Promise<void> => {
  const { items: originalItems, ...orderCompletedPropertiesWithoutItems } =
    orderCompletedProperties

  const coupon = orderCompletedProperties.promotions?.[0]?.name
  const trackingEvents = [
    zaraz.track('checkoutCompleted', {
      ...orderCompletedPropertiesWithoutItems,
      products: originalItems.map(fullCartItemToZarazTrackProduct)
    }),
    zaraz.ecommerce('Order Completed', {
      checkout_id: orderCompletedProperties.orderNumber,
      order_id: orderCompletedProperties.orderNumber,
      total: orderCompletedProperties.total,
      revenue: orderCompletedProperties.subtotalAfterDiscount,
      shipping: orderCompletedProperties.shipping,
      tax: orderCompletedProperties.tax,
      discount: orderCompletedProperties.discount,
      currency: 'USD',
      products: originalItems.map(fullCartItemToZarazProduct),
      coupon
    })
  ]

  const hasCsrCode = orderCompletedProperties.promotions.some(
    (promotion) => promotion.code?.substring(0, 3).toUpperCase() == 'CSR'
  )
  if (!hasCsrCode) {
    const items = originalItems.map((item) => {
      return {
        ...fullCartItemToGtmProduct(item),
        variant: item.canonicalColor,
        majorCategory: item.canonicalCategory.majorCategory,
        minorCategory: item.canonicalCategory.minorCategory
      }
    })
    trackingEvents.push(
      gtm.trackEvent('checkoutCompleted', {
        ...orderCompletedProperties,
        googleTagParams: {
          ecomm_prodid: originalItems.map((item) => item.code),
          ecomm_pagetype: 'purchase',
          ecomm_totalvalue: orderCompletedProperties.subtotalAfterDiscount,
          ecomm_quantity: originalItems.map((item) => item.quantity),
          hasaccount: 'y'
        },
        ecommerce: {
          purchase: {
            actionField: {
              id: orderCompletedProperties.orderNumber,
              revenue: orderCompletedProperties.total.toString(),
              tax: orderCompletedProperties.tax.toString(),
              shipping: orderCompletedProperties.shipping.toString(),
              coupon
            },
            products: items
          }
        }
      })
    )
  }
  analytics.woopraTrack('order', {
    id: orderCompletedProperties.orderNumber,
    total: orderCompletedProperties.total,
    tax: orderCompletedProperties.tax,
    shipping: orderCompletedProperties.shipping,
    // snake_case maintains compatibility with legacy data
    subtotal_less_discount: orderCompletedProperties.subtotalAfterDiscount,
    subtotalBeforeDiscount: orderCompletedProperties.subtotalBeforeDiscount,
    discount: orderCompletedProperties.discount
  })
  orderCompletedProperties.items.forEach(function (item) {
    // snake_case maintains compatibility with legacy data
    analytics.woopraTrack('productpurchase', legacyWoopraKeysToSnake(item))
  })
  orderCompletedProperties.promotions.forEach((promotion) =>
    // snake_case maintains compatibility with legacy data
    analytics.woopraTrack('promotionused', legacyWoopraKeysToSnake(promotion))
  )
  return withTimeout(...trackingEvents)
}

export const trackStartedAddToCart = async (
  ...addToCartProperties: AddToCartProperties[]
): Promise<void> => {
  const trackPromises = addToCartProperties.map((cartProperty) => {
    const fullCartItemFlat = fullCartItemToFlatten(cartProperty.item)
    const mktoPageData = pageDataUtils.setPageData(fullCartItemFlat)
    return track(
      'Started add to cart',
      {
        category: 'Cart',
        ...mktoPageData,
        ...cartProperty
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  })
  return withTimeout(...trackPromises)
}
export const trackFinishedAddToCart = async (
  ...addToCartProperties: AddToCartProperties[]
): Promise<void> => {
  // Filtering to only fire when adding to the cart (v. saving the proof/project) for the first time
  const productsNotPreviouslyAddedToCart = addToCartProperties.filter((item) =>
    'hasNotPreviouslyBeenAddedToCart' in item
      ? item.hasNotPreviouslyBeenAddedToCart
      : true
  )

  const trackPromises = addToCartProperties.map((cartProperty) => {
    const fullCartItemFlat = fullCartItemToFlatten(cartProperty.item)
    const mktoPageData = pageDataUtils.setPageData(fullCartItemFlat)

    return track(
      'Finished add to cart',
      {
        category: 'Cart',
        ...mktoPageData,
        ...cartProperty
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  })

  if (productsNotPreviouslyAddedToCart.length > 0) {
    trackPromises.push(
      gtm.trackEvent('addToCart', {
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: productsNotPreviouslyAddedToCart.map((properties) => {
              return fullCartItemToGtmProduct(properties.item)
            })
          }
        }
      }),
      zaraz.track('addToCart', {
        products: productsNotPreviouslyAddedToCart.map((properties) => {
          return fullCartItemToZarazTrackProduct(properties.item)
        })
      }),
      zaraz.ecommerce('Product Added', {
        products: productsNotPreviouslyAddedToCart.map((properties) => {
          return fullCartItemToZarazProduct(properties.item)
        })
      })
    )
  }
  return withTimeout(...trackPromises)
}

export const trackViewedProductPage = async (
  viewedItem: FullCartItem
): Promise<void> => {
  return withTimeout(
    zaraz.ecommerce('Product Viewed', fullCartItemToZarazProduct(viewedItem))
  )
}

export const trackViewedCart = async (viewedCartItems: BaseCartItem[]) => {
  return withTimeout(
    zaraz.ecommerce('Cart Viewed', {
      products: await getZarazMappedItems(...viewedCartItems)
    })
  )
}

export const trackRemovedCartItem = async (
  ...removedCartItems: BaseCartItem[]
) => {
  return withTimeout(
    gtm.trackEvent('removeFromCart', {
      ecommerce: {
        remove: {
          products: await getGtmMappedItems(...removedCartItems)
        }
      }
    }),
    zaraz.ecommerce('Product Removed', {
      products: await getZarazMappedItems(...removedCartItems)
    })
  )
}

const trackAddedToFavorites = async (favorites: FullCartItem[]) => {
  return withTimeout(
    zaraz.ecommerce('Product Added to Wishlist', {
      products: favorites.map(fullCartItemToZarazProduct)
    })
  )
}

const trackRemovedFromFavorites = async (favorites: FullCartItem[]) => {
  return withTimeout(
    zaraz.track('removedFavorite', {
      products: favorites
    })
  )
}

export const trackFavoriteAction = async (
  favoriteActionProperties: FavoriteActionProperties
) => {
  const itemIdToItem = await itemsJson(...favoriteActionProperties.itemIds)
  const favorites: FullCartItem[] = favoriteActionProperties.itemIds.map(
    (item) => {
      // Always defined because `favoriteActionProperties.itemIds` is used to populate `itemIdToItem`
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const favorite = itemIdToItem[item]!
      return {
        id: favorite.id,
        code: favorite.code,
        designerName: favorite.designerName,
        pageTitle: favorite.pageTitle,
        // All items have at last 1 price
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        price: favorite.prices[favorite.prices.length - 1]!,
        quantity: favorite.minQuantity,
        canonicalColor: favorite.canonicalColor,
        canonicalCategory: favorite.canonicalCategory
      }
    }
  )

  const trackPromises = favorites.map((favorite) =>
    track(favoriteActionProperties.action, {
      category: 'Favorites',
      label: favoriteActionProperties.initiator,
      value: favorite.id
    })
  )

  if (favoriteActionProperties.action === 'Favorited') {
    trackPromises.push(trackAddedToFavorites(favorites))
  } else if (favoriteActionProperties.action === 'Unfavorited') {
    trackPromises.push(trackRemovedFromFavorites(favorites))
  }

  return withTimeout(...trackPromises)
}

export const trackOpenedFavorites = async () => {
  return withTimeout(zaraz.track('openedFavorites'))
}

export const trackClosedFavorites = async () => {
  return withTimeout(zaraz.track('closedFavorites'))
}

const searchCategoryProperties = ({
  categoryId,
  categoryName,
  query,
  filters,
  sortBy
}: SearchCategoryProperties) => {
  const idComponents: string[] = []
  const nameComponents: string[] = []
  if (categoryId) {
    idComponents.push(`categoryId=${categoryId}`)
    nameComponents.push(`Category: ${categoryName} (${categoryId})`)
  }
  if (query) {
    const normalizedQuery = query.toLowerCase()
    idComponents.push(`search=${normalizedQuery}`)
    nameComponents.push(`Search: ${normalizedQuery}`)
  }
  if (filters.length) {
    idComponents.push(`filters=${filters.join(',')}`)
    nameComponents.push(`Filtered by ${filters.join(', ')}`)
  }
  if (sortBy) {
    idComponents.push(`sortBy=${sortBy}`)
    nameComponents.push(`Sorted by ${sortBy}`)
  }

  return {
    // Compact representation
    item_list_id: idComponents.join('|'),
    // Human-friendly list name
    category: nameComponents.join(' | ')
  }
}

export const trackClickedProductListItem = async (
  clickedItemProperties: ClickedItemProperties
) => {
  const clickedProperties = searchCategoryProperties(clickedItemProperties)
  return withTimeout(
    zaraz.ecommerce('Product Clicked', {
      products: [
        fullCartItemToZarazProduct({
          ...clickedItemProperties.item,
          quantity: 1
        })
      ],
      position: clickedItemProperties.position,
      category: clickedProperties.category,
      item_list_id: clickedProperties.item_list_id
    })
  )
}

export const trackCanceledOrder = async (
  canceledOrderProperties: CanceledOrderProperties
): Promise<void> => {
  const { items, ...orderProperties } = canceledOrderProperties
  const products = items.map(fullCartItemToFlatten)
  return withTimeout(
    track(
      'Canceled order',
      {
        ...orderProperties,
        products,
        category: 'Account',
        label:
          canceledOrderProperties.reasonOther != null
            ? canceledOrderProperties.reason +
              ': ' +
              canceledOrderProperties.reasonOther
            : canceledOrderProperties.reason
      },
      {
        integrations: {
          woopra: true
        }
      }
    ),
    zaraz.ecommerce('Order Refunded', {
      ...orderProperties,
      products: items.map(fullCartItemToZarazTrackProduct)
    })
  )
}

export const trackStartedSaveForLater = async (
  savedForLaterProperties: SavedForLaterProperties
): Promise<void> => {
  const fullCartItemFlat = fullCartItemToFlatten(savedForLaterProperties.item)
  const mktoPageData = pageDataUtils.setPageData(fullCartItemFlat)
  return withTimeout(
    track(
      'Started save for later',
      {
        ...savedForLaterProperties,
        ...mktoPageData,
        category: 'Cart'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}
export const trackFinishedSaveForLater = async (
  savedForLaterProperties: SavedForLaterProperties
): Promise<void> => {
  const fullCartItemFlat = fullCartItemToFlatten(savedForLaterProperties.item)
  const mktoPageData = pageDataUtils.setPageData(fullCartItemFlat)

  return withTimeout(
    track(
      'Finished save for later',
      {
        ...savedForLaterProperties,
        ...mktoPageData,
        category: 'Cart'
      },
      {
        integrations: {
          woopra: true
        }
      }
    ),
    zaraz.track('savedForLater', savedForLaterProperties)
  )
}

export const trackViewedProductList = async (
  ...viewedProductListProperties: ViewedProductListProperties[]
) => {
  if (!viewedProductListProperties[0]) {
    return
  }
  const listViewedProperties = searchCategoryProperties(
    viewedProductListProperties[0]
  )
  return withTimeout(
    // Not adding per-item tracking for services that don't support the array of products (e.g. Marketo and Woopra),
    // because there would be a lot of events fired and performance and cost may be impacted.
    // There's not currently an expected use case for this data in those services, but this decision could be
    // reconsidered if a need arises.
    zaraz.ecommerce('Product List Viewed', {
      products: viewedProductListProperties.map((properties) => {
        return fullCartItemToZarazProduct({ ...properties.item, quantity: 1 })
      }),
      category: listViewedProperties.category,
      item_list_id: listViewedProperties.item_list_id
    })
  )
}

export const trackSearchedProducts = async (
  searchedProductsProperties: SearchedProductsProperties
) => {
  analytics.woopraTrack('search', searchedProductsProperties)
  return withTimeout(
    zaraz.ecommerce('Products Searched', {
      query: searchedProductsProperties.query
    })
  )
}

export const trackViewedPersonalizeStep = async (
  viewedPersonalizeStepProperties: ViewedPersonalizeStepProperties
) => {
  return withTimeout(
    track(
      'Show view',
      {
        ...viewedPersonalizeStepProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    ),
    zaraz.track('viewedPersonalizeStep', viewedPersonalizeStepProperties)
  )
}

export const trackSignedOut = async (): Promise<void> => {
  return withTimeout(
    track(
      'Signed Out',
      {
        category: 'Session',
        label: window.location.pathname
      },
      {
        // Don't defer because there's a decent chance that we're unloading the page at this time
        integrations: {
          marketo: false,
          woopra: true
        }
      }
    )
  )
}

export const trackChangedPassword = async () => {
  return withTimeout(
    track(
      'Changed Password',
      {
        category: 'Account',
        label: 'Success'
      },
      {
        defer: true, // Improve UI performance
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackChangedPasswordError = async (
  changedPasswordErrorProperties: ChangedPasswordErrorProperties
) => {
  return withTimeout(
    track(
      'Changed Password',
      {
        category: 'Account',
        label: 'Failure: ' + changedPasswordErrorProperties.message
      },
      {
        defer: true, // Improve UI performance
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackResetPassword = async () => {
  return withTimeout(
    track(
      'Password Reset',
      {
        category: 'Account',
        label: 'Success'
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackResetPasswordEmailNotFoundError = async () => {
  return withTimeout(
    track(
      'Password Reset',
      {
        category: 'Account',
        label: 'Failure: Email not found'
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackResetPasswordAjaxError = async (
  resetPasswordAjaxErrorProperties: AjaxErrorProperties
) => {
  return withTimeout(
    track(
      'Password Reset',
      {
        category: 'Account',
        label: `Failure: AJAX error (${resetPasswordAjaxErrorProperties.textStatus} / ${resetPasswordAjaxErrorProperties.errorThrown})`
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackCreatedAccountReferrer = async (
  createdAccountReferrerProperties: CreatedAccountReferrerProperties
) => {
  return withTimeout(
    track(
      `Referrer - ${createdAccountReferrerProperties.referrer}`,
      {
        category: 'Account',
        label: createdAccountReferrerProperties.referrerOther ?? ''
      },
      {
        // Improve UI performance
        defer: true,
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackChangedEmail = async (
  changedEmailProperties: ChangedEmailProperties
) => {
  return withTimeout(
    track(
      'Changed Email',
      {
        category: 'Account',
        label: 'Success',
        existingEmail: changedEmailProperties.existingEmail,
        newEmail: changedEmailProperties.newEmail
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackChangedEmailError = async (
  changedEmailErrorProperties: ChangedEmailErrorProperties
) => {
  return withTimeout(
    track(
      'Changed Email',
      {
        category: 'Account',
        label: 'Failure: ' + changedEmailErrorProperties.message,
        existingEmail: changedEmailErrorProperties.existingEmail,
        newEmail: changedEmailErrorProperties.newEmail
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackChangedName = async (
  changedNameProperties: ChangedNameProperties
) => {
  return withTimeout(
    track(
      'Changed Name',
      {
        category: 'Account',
        label: 'Success',
        firstName: changedNameProperties.firstName,
        lastName: changedNameProperties.lastName
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackChangedNameError = async (
  changedNameErrorProperties: ChangedNameErrorProperties
) => {
  return withTimeout(
    track(
      'Changed Name',
      {
        category: 'Account',
        label: 'Failure: ' + changedNameErrorProperties.message,
        firstName: changedNameErrorProperties.firstName,
        lastName: changedNameErrorProperties.lastName
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

/**
 * Image was upscaled in personalization.
 *
 * Currently, this is done silently and automatically on low DPI images.
 */
export const trackUpscaledImage = async (
  upscaledImageProperties: UpscaledImageProperties
) => {
  analytics.woopraTrack('upscaled_image', {
    ...upscaledImageProperties,
    megapixels:
      (upscaledImageProperties.width * upscaledImageProperties.height) / 1000000
  })
}

export const trackSavedProofError = async (
  savedProofErrorProperties: SavedProofErrorProperties
) => {
  const { message, ...contextProperties } = savedProofErrorProperties
  return withTimeout(
    track(
      'Error saving proof',
      {
        category: 'Personalize',
        label: message,
        ...contextProperties
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackSavedProofErrorOnFinishAddOnFlow = async (
  savedProofErrorOnFinishAddOnFlowProperties: SavedProofErrorOnFinishAddOnFlowProperties
) => {
  return withTimeout(
    track(
      'Error saving proof',
      {
        category: 'Personalize',
        label: 'updateProof in finishAddOnFlow',
        ...savedProofErrorOnFinishAddOnFlowProperties
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackRestoredMismatchedVersion = async (
  restoredMismatchedVersionProperties: RestoredMismatchedVersionProperties
) => {
  return withTimeout(
    track('Version Mismatch on Restore', {
      category: 'Personalize',
      ...restoredMismatchedVersionProperties
    })
  )
}

export const trackSavedProofOptions = async (
  savedProofOptionsProperties: SavedProofOptionsProperties
) => {
  return withTimeout(
    track(
      'Saved proof options',
      {
        ...savedProofOptionsProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackRestoredSkipToOptionStepFlow = async (
  restoredSkipToOptionStepFlowProperties: RestoredSkipToOptionStepFlowProperties
) => {
  return withTimeout(
    track('Restore skip to OptionStep flow', {
      ...restoredSkipToOptionStepFlowProperties,
      category: 'Personalize',
      label: document.referrer
    })
  )
}

export const trackRestoredInvalidAttemptedSkipToOptionStepFlow = async (
  restoredInvalidAttemptedSkipToOptionStepFlowProperties: RestoredInvalidAttemptedSkipToOptionStepFlowProperties
) => {
  return withTimeout(
    track(
      'Restore attempted skip to OptionStep flow - not valid for this item',
      {
        ...restoredInvalidAttemptedSkipToOptionStepFlowProperties,
        category: 'Personalize',
        label: document.referrer
      }
    )
  )
}

export const trackRevertedToDefaultEnvelope = async (
  revertedToDefaultEnvelopeProperties: RevertedToDefaultEnvelopeProperties
) => {
  return withTimeout(
    track(
      'Reverted to default envelope',
      { ...revertedToDefaultEnvelopeProperties, category: 'Personalize' },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackUpdatedStructuredReturnAddressInModal = async (
  structuredReturnAddressProperties: StructuredReturnAddressProperties
) => {
  return withTimeout(
    track(
      'Updated structured return address var data attributes in modal',
      {
        ...structuredReturnAddressProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackSubmittedInvalidUpdateStructuredReturnAddressInModal = async (
  invalidStructuredReturnAddressProperties: InvalidStructuredReturnAddressProperties
) => {
  return withTimeout(
    track(
      'Submitted invalid structured return address var data attributes in modal',
      {
        ...invalidStructuredReturnAddressProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackZoomedImage = async (
  zoomedImageProperties: ZoomedImageProperties
): Promise<void> => {
  return withTimeout(
    track('Zoom image', {
      ...zoomedImageProperties,
      category: 'Personalize'
    })
  )
}

export const trackAddedPhoto = async (
  addedPhotoProperties: AddedPhotoProperties
) => {
  return withTimeout(
    track(
      'Add photo',
      {
        ...addedPhotoProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackPreviewedPhoto = async (
  previewedPhotoProperties: PreviewedPhotoProperties
) => {
  return withTimeout(
    // Skip Woopra to save on actions since many preview events will probably fire
    track('Add photo', {
      ...previewedPhotoProperties,
      category: 'Personalize',
      label: 'Preview photo'
    })
  )
}

export const trackRotatedImage = async (
  rotatedImageProperties: RotatedImageProperties
) => {
  return withTimeout(
    track('Rotate image', {
      ...rotatedImageProperties,
      category: 'Personalize'
    })
  )
}

export const trackConfirmedLowDpiWarningSwitchPhoto = async (
  confirmedLowDpiWarningProperties: ConfirmedLowDpiWarningProperties
) => {
  return withTimeout(
    track(
      'Confirmed low DPI warning',
      {
        ...confirmedLowDpiWarningProperties,
        category: 'Personalize',
        label: 'Switch photo'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackConfirmedLowDpiWarningProceedAnyways = async (
  confirmedLowDpiWarningProperties: ConfirmedLowDpiWarningProperties
) => {
  return withTimeout(
    track(
      'Confirmed low DPI warning',
      {
        ...confirmedLowDpiWarningProperties,
        category: 'Personalize',
        label: 'Proceed anyways'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackRestoredProofError = async (
  restoredProofErrorProperties: RestoredProofErrorProperties
) => {
  return withTimeout(
    track(
      'Error in proof restore',
      {
        ...restoredProofErrorProperties,
        category: 'Personalize'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackDeletedUnstructuredReturnAddress = async () => {
  return withTimeout(
    track(
      'Deleted unstructured return address var data attributes',
      { category: 'Personalize' },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackLoadedProofFromVersion = async (
  loadedProofFromVersionProperties: LoadedProofFromVersionProperties
) => {
  return withTimeout(
    track('Load Proof from Version', {
      ...loadedProofFromVersionProperties,
      category: 'Personalize'
    })
  )
}

export const trackClickedProductPagePopover = async (
  clickedProductPagePopoverProperties: ClickedProductPagePopoverProperties
) => {
  return withTimeout(
    track('Click Popover', {
      ...clickedProductPagePopoverProperties,
      category: 'Product Page',
      label: 'Material'
    })
  )
}

export const trackClickedProductPageTab = async (
  clickedProductPageTabProperties: ClickedProductPageTabProperties
) => {
  return withTimeout(
    track('Click Tab', {
      ...clickedProductPageTabProperties,
      category: 'Product Page'
    })
  )
}

export const trackSelectedBulkUploadFormatOptionOriginalCase = async () => {
  return withTimeout(
    track('Selected bulk upload format option', {
      category: 'Address Book',
      label: 'Used original case'
    })
  )
}

export const trackSelectedBulkUploadFormatOptionProperCase = async () => {
  return withTimeout(
    track('Selected bulk upload format option', {
      category: 'Address Book',
      label: 'Kept proper case'
    })
  )
}

export const trackActivatedPromotionCode = async () => {
  return withTimeout(
    track('Code sent', {
      category: 'Promotion code activation'
    })
  )
}

export const trackActivatedPromotionCodeNotFoundError = async () => {
  return withTimeout(
    track('Code not found', {
      category: 'Promotion code activation'
    })
  )
}

export const trackOverflowedDedicationTextBox = async (
  overflowedDedicationTextBoxProperties: OverflowedDedicationTextBoxProperties
) => {
  return withTimeout(
    track(
      'Overflowed text box',
      {
        ...overflowedDedicationTextBoxProperties,
        category: 'Dedication'
      },
      {
        integrations: {
          woopra: true
        }
      }
    )
  )
}

export const trackUnreadZendeskWebWidgetMessages = async (
  unreadZendeskWebWidgetMessagesProperties: UnreadZendeskWebWidgetMessagesProperties
) => {
  analytics.woopraTrack(
    'unread_zendesk_web_widget_messages',
    unreadZendeskWebWidgetMessagesProperties
  )
  return withTimeout(
    zaraz.track(
      'unreadZendeskWebWidgetMessages',
      unreadZendeskWebWidgetMessagesProperties
    )
  )
}
