import _ from 'underscore'
import { Breadcrumb, Scope, SeverityLevel } from '@sentry/browser'
import { Primitive } from '@sentry/types'
import { flatten } from '@/utils/objectUtils'

//  This method allows us to aggregate templates with multiple URLs
const getTemplateName = function (pageType?: string): string | null {
  if (document.getElementById('layout-home-new')) {
    return 'homeNew'
  } else if (document.getElementById('layout-landing')) {
    return 'landing'
  } else if (document.getElementById('layout-lead-gen')) {
    return 'leadGen'
  } else if (pageType == 'category' || pageType == 'search') {
    return 'facetedSearch'
  } else if (pageType) {
    //  URLs with a uniquely named template don't need to be tagged.
    return pageType
  } else {
    return null
  }
}

interface SentryOptions {
  severity?: SeverityLevel
  tags?: { [key: string]: string }
}

const addTagsAndExtras = function (
  scope: Scope,
  tags: { [key: string]: Primitive },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any }
): void {
  if (tags) {
    for (const key of Object.keys(tags)) {
      scope.setTag(key, tags[key])
    }

    if (data) {
      for (const key of Object.keys(data)) {
        if (key == 'jqXHR') {
          // If we're given a jqXHR key we only want to report the meaningful parts of it, e.g. we don't need
          // jqXHR.abort(), jqXHR.always(), jqXHR.complete(), etc.
          const jqXhrSummary: { [key: string]: unknown } = _.pick(
            data.jqXHR,
            'responseJSON',
            'responseXML',
            'responseText',
            'readyState',
            'status',
            'statusText'
          )

          //  If jqXHR is undefined or isn't a real jqXHR, getAllResponseHeaders may not exist
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (typeof (data.jqXHR?.getAllResponseHeaders as any) == 'function') {
            jqXhrSummary.responseHeaders = data.jqXHR.getAllResponseHeaders()
          }

          if (Object.keys(jqXhrSummary).length < 0) {
            scope.setExtra('jqXHR', jqXhrSummary)
          }
        } else {
          scope.setExtra(key, data[key])
        }
      }
    }
  }
}

const withScope = function (
  options: SentryOptions = {},
  callback: () => void,
  data?: { [key: string]: unknown }
): void {
  const optionTags = options.tags || {}
  const tags = flatten({
    ...optionTags,
    ...window._mktoPageData,
    template: getTemplateName(
      optionTags.pageType || window._mktoPageData?.pageType
    )
  }) as { [key: string]: Primitive }

  // We must force load the SDK since Loader uses sets `lazy = true` by default, so when you attempt to use `withScope`
  // the request fails to be made to Sentry. See indepth information here:
  // https://docs.sentry.io/platforms/javascript/loader/#what-does-the-loader-provide
  Sentry.forceLoad()
  // We prefer `withScope` since this is a data specific to a single error message
  // Read more on withScope here: https://docs.sentry.io/enriching-error-data/scopes/?platform=browser// local-scopes
  Sentry.withScope((scope: Scope) => {
    addTagsAndExtras(scope, tags, data)
    callback()
  })
}

/**
 * Logs an error to Sentry
 * @param {string} [message] - The name of the error, this is what Sentry will represent the error as inside
 *  the error list.
 * @param {Object} [data] - This is supplemental data used to assist in the resolution of the reported error. If
 * you're defining a some sort of error explanation, providing structured data, etc, it should go here.
 * @param {string} [options.severity=error] - The severity of the error, may be: debug, info, warning, error, or
 *  critical. Each type of error has their own method, e.g. if you need to send a warning, use the warning() method.
 * @param {Object} [options.tags] - Sentry implements a system it calls tags. Tags are key/value string pairs which
 * generate aggregations, breakdowns charts, and search filters.
 * Tags are assigned to an event, and can later be used as a breakdown or quick access to finding related events.
 * Several common uses for tags include:
 *   - The hostname of the server
 *   - The version of your platform (e.g. iOS 5.0)
 *   - The user’s language
 * Sentry will automatically index all tags for an event, as well as the frequency and the last time a value has been
 * seen.
 * They keep track of the number of distinct tags, and can assist in you determining hotspots for various issues.
 */
export const log = function (
  message: string,
  data?: { [key: string]: unknown },
  options: SentryOptions = {}
): void {
  const severity: SeverityLevel = options.severity || 'error'
  withScope(options, () => Sentry.captureMessage(message, severity), data)
}

/**
 * Logs an exception to Sentry.
 *
 * Prefer using this over `error`, when `Error` object may be available.
 *
 * @param error - Ideally, an `Error` object to ensure a stack trace is included,
 * but non-`Error` objects and strings are also acceptable.
 * @param data - Supplemental data used to assist in the resolution of the reported error.
 * If defining a some sort of error explanation, providing structured data, etc, use `data`.
 * @param options - Add severity and tags to the event.
 */
export const exception = function (
  error: unknown,
  data?: { [key: string]: unknown },
  options?: SentryOptions
): void {
  //  Exceptions are logged as errors without declaring severity, so we don't need to worry about the severity option
  withScope(options, () => Sentry.captureException(error), data)
}

export const debug = function (
  message: string,
  data?: { [key: string]: unknown },
  options?: SentryOptions
): void {
  log(message, data, _.extend({}, options, { severity: 'debug' }))
}

export const info = function (
  message: string,
  data?: { [key: string]: unknown },
  options?: SentryOptions
): void {
  log(message, data, _.extend({}, options, { severity: 'info' }))
}

export const warn = function (
  message: string,
  data?: { [key: string]: unknown },
  options?: SentryOptions
): void {
  //  We prefer warn for its consistency with other logging libraries and its brevity,
  //  but Sentry uses warning vs warn
  log(message, data, _.extend({}, options, { severity: 'warning' }))
}

export const error = function (
  message: string,
  data?: { [key: string]: unknown },
  options?: SentryOptions
): void {
  log(message, data, _.extend({}, options, { severity: 'error' }))
}

/**
 * Sentry supports a concept called Breadcrumbs, which is a trail of events which happened prior to an issue. Often
 * times these events are very similar to traditional logs, but also have the ability to record more rich structured
 * data.
 * @param {string} [options.message] - A string describing the event. The most common vector, often used as a drop-in for a
 * traditional log.
 * @param {Object} [options.data] - A mapping (str => str) of metadata around the event. This is often used instead
 * of message, but may also be used in addition. All keys here are rendered out as table in the breadcrumb on display
 * but some crumb types might special case some keys.
 * @param {string} [options.category] - A category to label the event under. This generally is similar to a logger
 * name, and will let you more easily understand the area an event took place, such as auth.
 * @param {string} [options.level=info] - The level may be any of fatal, error, warning, info, or debug.
 * @see {@link https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browser}
 */
export const addBreadcrumb = function (options: Breadcrumb): void {
  Sentry.forceLoad()
  Sentry.addBreadcrumb(options)
}
