import { ofetch } from 'ofetch'

export enum ShippingGroup {
  Free = 'Free',
  Bamboo = 'Bamboo',
  Default = 'DEFAULT',
  WallDecal = 'Wall Decal'
}

export enum Capitalization {
  NormalCaps = 'NORMALCAPS',
  AllCaps = 'ALLCAPS',
  AllSmallCaps = 'ALLSMALLCAPS',
  SmallCaps = 'SMALLCAPS'
}

export enum CanonicalColor {
  Pink = 'pink',
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  Black = 'black',
  Gray = 'gray',
  White = 'white',
  Beige = 'beige',
  Brown = 'brown',
  Multi = 'multi'
}

export enum Justification {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export enum Gender {
  Male = 'm',
  Female = 'f',
  Neutral = 'n'
}

export enum ItemQuantitySyncBehavior {
  // Match the quantity of the parent proof (the project's main /personalized item).
  // This is the total quantity of the item that the user is paying for including shipped and mailed.
  Main = 'MAIN',
  // Number of people in the address book regardless of shipped with recipient addressing or mailed
  Recipients = 'RECIPIENTS',
  // Number of non recipient addressed (shipped) or mailed envelopes
  MainMinusRecipients = 'MAIN_MINUS_RECIPIENTS',
  // Quantity of the parent proof, multiplied by number found in the parent proof's fulfillment attributes,
  // for the key defined in the parent's item's `quantitySyncFulfillmentAttribute` property.
  MainMultipliedByFulfillmentAttribute = 'MAIN_MULTIPLIED_BY_FULFILLMENT_ATTRIBUTE'
}

/**
 * Defined on an add-on item to define which items' quantities will be summed for select `ItemQuantitySyncBehavior`s.
 */
export enum ItemQuantitySyncGroup {
  // Add-on (i.e. domestic and international) quantities in this group will add up to the total number of recipients.
  Recipients = 'RECIPIENTS'
}

export enum PhotoFilter {
  Color = 'color',
  Sepia = 'sepia',
  BlackAndWhite = 'bw'
}

export enum BackStripOverlayLayout {
  // Don't draw a strip at all. Same as if the default is null, but is an option so
  // that we explicitly set BLANK in fulfillment attributes to override a default behavior.
  Blank = 'BLANK',
  // Used on most cards. Has rules to define standard positioning using general rules.
  Standard = 'STANDARD',
  // Rule-based but for circles.
  Circle = 'CIRCLE',
  // Original raised strip from standard cards.
  // Rectangle has rounded corners on the right.
  StandardLegacy = 'STANDARD_LEGACY',
  // Rectangle goes all the way to the edge of the card.
  // This is the original bottom or right aligned strip for #10 cards.
  Number10Legacy = 'NUMBER_10_LEGACY'
}

export enum BackStripOverlayTheme {
  // White background with black text
  WhiteBackground = 'WHITE_BACKGROUND',
  // No background with black text
  BlackTextOnly = 'BLACK_TEXT_ONLY',
  // No background with white text
  WhiteTextOnly = 'WHITE_TEXT_ONLY'
}

export enum EnvelopeAddressingMode {
  Blank = 'BLANK',
  ExtraBlank = 'EXTRA_BLANK',
  PrintedReturnAddress = 'PRINTED_RETURN_ADDRESS',
  ExtraPrintedReturnAddress = 'EXTRA_PRINTED_RETURN_ADDRESS',
  Response = 'RESPONSE',
  ExtraResponse = 'EXTRA_RESPONSE',
  PrintedRecipientAddress = 'PRINTED_RECIPIENT_ADDRESS',
  Mailed = 'MAILED'
}

/**
 * A property on envelopes that differentiates from a pricing standpoint.
 * Used in the UI to split envelopes by section, so that the user can
 * see which envelopes cost extra.
 */
export enum EnvelopeClass {
  Basic = 'basic',
  Premium = 'premium'
}

// export enum EnvelopeStaticColor {
//   Black = 'black'
// }

export enum FoilColor {
  Gold = 'GOLD',
  Silver = 'SILVER',
  Rose = 'ROSE'
}

export interface CanonicalCategory {
  readonly id: number
  name: string
  majorCategory: string
  minorCategory: string
  seoPath: string
}

export interface Format {
  readonly enumName: string
  name: string
  type: string
  prettyDimensions: string
  iconCaption: string
  surfaces: Surface[]
}

export interface Surface {
  readonly id: number
  readonly enumName: string
  name: string
  index: number
  longDimensionWithBleed: number
  shortDimensionWithBleed: number
  isHidden: boolean
  maskBleed: number
  variants: SurfaceVariant[]
}

export interface SurfaceVariant {
  code: string
  version: number
  artworkImageUrl: string
  customMaskImageUrl?: string
  objects: SurfaceTextBox[] | SurfaceImage[]
  // textBoxes: SurfaceTextBox[]
  // photos: SurfacePhoto[]
  width: number
  height: number
  textImageUrl: string
  categoryThumbnailUrl: string
}

export interface SurfaceObject {
  id: number
  x: number
  y: number
  width: number
  height: number
  zIndex: number
  type: string
}

// export interface SurfacePhoto extends SurfaceObject {
//   type: Color
// }

export interface SurfaceImage extends SurfaceObject {
  // TODO: Remove this? No longer used?
  filter: PhotoFilter
}

export interface SurfaceTextBox extends SurfaceObject {
  phrases: Phrase[]
  rotationDegrees: number
  autoScaleMode: AutoScaleMode
  autoScaleModeMinSizePt: number
}

export interface Phrase {
  id: number
  font: string
  leading: number
  charSpace: number
  rise: number
  fillColor?: Color
  strokeColor?: Color
  strokeWeight: number
  justification: Justification
  capitalization: Capitalization
  text: string
  fontSizePt: number
  hScale: number

  toCompactEncoding(): string
}

export interface Color {
  c: number
  m: number
  y: number
  k: number
  r: number
  g: number
  b: number
}

export enum AutoScaleMode {
  NoAutoScale,
  NoWrap,
  FitToBox
}

export enum ProofApprovalBehavior {
  Full = 'FULL',
  ExpressOnly = 'EXPRESS_ONLY',
  Wedding = 'WEDDING'
}

export enum Orientation {
  Horizontal = 'H',
  Vertical = 'V',
  Square = 'S'
}

export interface EnvelopeItem extends AddOnItem {
  id: number
  price: number
  quantitySyncBehavior: ItemQuantitySyncBehavior | null
  envelopeStyleId: string
  hugeImageUrl: string
  envelopeStyleName: string
  envelopeAddressingMode: EnvelopeAddressingMode
  envelopeClass: EnvelopeClass | null
  //envelopeStaticColor?: EnvelopeStaticColor
}

export interface Envelope {
  formatSubstrateEnumName: string
  envelopeSizeEnumName: string
  envelopeSubstrateEnumName: string
  isHidden: boolean
  item: EnvelopeItem | null
}

export interface AddOn {
  enumName: string
  name: string
  description: string
  tagline: string
}

export type EnvelopeSize = AddOn

export type EnvelopeSubstrate = AddOn

export type BaseMaterial = AddOn

export interface FormatSubstrate extends AddOn {
  thicknessName: string
  thicknessDescription: string
  thicknessTagline: string
  baseMaterialEnumName: string
  item: AddOnItem | null
}

export interface AddOnItem {
  id: number
  price: number
  quantitySyncBehavior: ItemQuantitySyncBehavior | null
}

export interface Trim {
  enumName: string
  name: string
  item: AddOnItem | null
}

export interface Item {
  readonly id: number
  readonly code: string
  shortTitle: string
  pageTitle: string
  isHidden: boolean
  designerName: string
  seoPath: string
  hugeImageUrl: string
  prices: number[]
  discountedPrices: number[]
  discountPercentage: number
  priceQuantities: number[]
  isTaxable: boolean
  minQuantity: number
  maxQuantity: number
  quantitySyncBehavior: ItemQuantitySyncBehavior | null
  quantitySyncFulfillmentAttribute: 'extraPageCount' | null
  quantitySyncGroup: ItemQuantitySyncGroup | null
  quantityIncrement: number
  quantityInSet: number
  isDirectToCart: boolean
  // TODO: Deprecated. Remove from type once removed from the API response.
  marketingColor: string
  canonicalColor: string
  primaryDesignerHex: string
  foilColor?: FoilColor | null
  itemWarning?: string | null
  shippingGroup: ShippingGroup
  breadcrumbCategories: string[]
  canonicalCategory: CanonicalCategory
  format: Format
  proofApprovalBehavior: ProofApprovalBehavior
  orientation: Orientation
  backStripOverlayTheme?: BackStripOverlayTheme
  backStripOverlayLayout: BackStripOverlayLayout
  envelopes: Envelope[]
  envelopeSizes: EnvelopeSize[]
  envelopeSubstrates: EnvelopeSubstrate[]
  formatSubstrateEnumNamesByBaseMaterial: {
    [baseMaterialEnumName: string]: string[]
  }
  defaultFormatSubstrateEnumName: string
  trimEnumNamesByFormatSubstrate: {
    [formatSubstrateEnumName: string]: string[]
  }
  defaultTrimEnumNameByFormatSubstrate: {
    [formatSubstrateEnumName: string]: string
  }
  trims: Trim[]
  baseMaterials: BaseMaterial[]
  formatSubstrates: FormatSubstrate[]
}

export async function getItem(idOrCode: number | string): Promise<Item> {
  return await ofetch<Item>(`/api/v3/items/${idOrCode}`)
}
