import { resolveAfterTimeout } from '@/utils/promise'
import {
  CanonicalCategory,
  ShareProperties,
  SignedInProperties,
  Promotion,
  SavedForLaterProperties,
  ViewedPersonalizeStepProperties,
  UnreadZendeskWebWidgetMessagesProperties
} from '@/analytics/types'
import { roundCurrency } from '@/utils/number'

type EventName =
  /** Email is submitted without signing up for a full account with a password, e.g. subscribing to email alerts */
  | 'leadCreated'
  /** A full account with a password is created */
  | 'accountCreated'
  /** Product added to cart */
  | 'addToCart'
  /** Order is placed and payment is successfully submitted for the items in the cart */
  | 'checkoutCompleted'
  /** Clicked social network button */
  | 'sharedContent'
  /** Product removed from favorites */
  | 'removedFavorite'
  | 'openedFavorites'
  | 'closedFavorites'
  /** The user has signed in  */
  | 'signedIn'
  /** Order canceled from my account orders */
  | 'canceledOrder'
  /** A proof was saved for later */
  | 'savedForLater'
  /** Moved to a new step during the personalization */
  | 'viewedPersonalizeStep'
  /** Zendesk Web Widget unread messages callback was triggered */
  | 'unreadZendeskWebWidgetMessages'

interface Product {
  id: number
  code: string
  pageTitle: string
  /** The unit price including the quantity discount */
  price: number
  quantity: number
  canonicalCategory: CanonicalCategory
}

/** Requires fields Paper Culture always sends for a Zaraz e-commerce product. */
export type ZarazEcommerceProduct = ZarazEcommerceProductParameters & {
  product_id: string
  sku: string
  name: string
  brand: string
  variant: string
  price: number
  quantity: number
  category: string
}

type AddToCartProperties = {
  products: Product[]
}

type AccountCreatedProperties = {
  email: string
  referrerSource: string
  method: string
}

type LeadCreatedProperties = {
  email: string
}

type CheckoutCompletedProperties = {
  subtotalBeforeDiscount: number
  subtotalAfterDiscount: number
  orderNumber: string
  total: number
  tax: number
  shipping: number
  discount: number
  promotions: Promotion[]
  products: Product[]
}

type CanceledOrderProperties = CheckoutCompletedProperties & {
  reason:
    | 'make-changes'
    | 'too-expensive'
    | 'prefer-competitor'
    | 'not-needed'
    | 'other'
  reasonOther: string | null
  // code is not exposed in My Account or order APIs to avoid leaking internal or secret codes
  promotions: Omit<Promotion, 'code'>[]
}

type FavoriteProperties = {
  products: Product[]
}

function ensureResolveWithTimeout<T>(promise?: Promise<T>): Promise<T | void> {
  return resolveAfterTimeout(
    promise
      // Zaraz track/ecommerce may not return a Promise if Zaraz hasn't fully initialized yet,
      // e.g. while the page is still loading, or if Zaraz was blocked.
      // In that scenario, this function's Promise will resolve immediately.
      ?.catch(() =>
        // Ensure we always resolve (analytics are best-effort only)
        Promise.resolve()
      ),
    500
  )
}

export function track(
  eventName: 'addToCart',
  addToCartProperties: AddToCartProperties
): Promise<void>
/**
 *Track an event for a user creating a Paper Culture account with a password.
 */
export function track(
  eventName: 'accountCreated',
  accountCreatedProperties: AccountCreatedProperties
): Promise<void>
/**
 *Track an event for user email submission creating a lead.
 */
export function track(
  eventName: 'leadCreated',
  leadCreatedProperties: LeadCreatedProperties
): Promise<void>
/**
 *Track an event for checkout once order is completed.
 */
export function track(
  eventName: 'checkoutCompleted',
  checkoutCompletedProperties: CheckoutCompletedProperties
): Promise<void>
export function track(
  eventName: 'canceledOrder',
  canceledOrderProperties: CanceledOrderProperties
): Promise<void>
/**
 *Track an event for user when signed in
 */
export function track(
  eventName: 'signedIn',
  signedInProperties: SignedInProperties
): Promise<void>
/**
 *Track an event for a clicked social media button.
 */
export function track(
  eventName: 'sharedContent',
  shareProperties: ShareProperties
): Promise<void>
export function track(
  eventName: 'removedFavorite',
  removeFavoriteProperties: FavoriteProperties
): Promise<void>
export function track(eventName: 'openedFavorites'): Promise<void>
export function track(eventName: 'closedFavorites'): Promise<void>
/**
 *Track an event when a proof is saved
 */
export function track(
  eventName: 'savedForLater',
  savedForLaterProperties: SavedForLaterProperties
): Promise<void>
/**
 * Track an event
 */
export function track(
  eventName: 'viewedPersonalizeStep',
  viewedPersonalizeStepProperties: ViewedPersonalizeStepProperties
): Promise<void>
export function track(
  eventName: 'unreadZendeskWebWidgetMessages',
  unreadZendeskWebWidgetMessagesProperties: UnreadZendeskWebWidgetMessagesProperties
): Promise<void>
/**
 * Wrapper function for Zaraz pixel tracking functionality.
 *
 * @param eventName The name of the event to be tracked. Must be one of the four allowed events.
 * addToCart, accountCreated, leadCreated, checkoutCompleted
 * @param eventProperties An object representing the data associated with an event
 */
export async function track(
  eventName: EventName,
  eventProperties?: { [key: string]: unknown }
): Promise<void> {
  if (!window.zaraz) {
    // eslint-disable-next-line no-console
    console.debug(
      '[Disabled] zaraz.track( eventName =',
      eventName,
      ', eventProperties =',
      eventProperties,
      ')'
    )
  } else {
    return ensureResolveWithTimeout(
      window.zaraz.track(eventName, eventProperties)
    )
  }
}

/**
 * Make a variable available in all your events without
 * manually setting it every time you are using zaraz.track().
 */
export const set = (
  key: string,
  value: string,
  options?: ZarazSetOptions
): void => {
  if (!window.zaraz) {
    // eslint-disable-next-line no-console
    console.debug(
      '[Disabled] zaraz.set( key =',
      key,
      ', value =',
      value,
      ', options =',
      options,
      ')'
    )
  } else {
    window.zaraz.set(key, value, options)
  }
}
/**
 * Allows you to track common events of the ecommerce user journey without mapping the event to a trigger.
 */
export const ecommerce = async (
  eventName: string,
  eventParameters: ZarazEcommerceParameters
): Promise<void> => {
  /** Event parameters with automatically calculated fields and defaults */
  const parametersWithDefaults = Object.assign({}, eventParameters)
  // Default value for an array of products
  if (
    parametersWithDefaults.value === undefined &&
    parametersWithDefaults.products
  ) {
    parametersWithDefaults.value = parametersWithDefaults.products.reduce(
      (sum, product) =>
        roundCurrency(sum + (product.quantity || 1) * (product.price || 0)),
      0
    )
  }
  // Default value for a single product
  if (
    parametersWithDefaults.value === undefined &&
    parametersWithDefaults.price
  ) {
    parametersWithDefaults.value =
      (parametersWithDefaults.quantity || 1) * parametersWithDefaults.price
  }
  // Default currency
  if (
    parametersWithDefaults.currency === undefined &&
    parametersWithDefaults.value !== undefined
  ) {
    parametersWithDefaults.currency = 'USD'
  }
  if (!window.zaraz) {
    // eslint-disable-next-line no-console
    console.debug(
      '[Disabled] zaraz.ecommerce( eventName =',
      eventName,
      ', eventParameters =',
      parametersWithDefaults,
      ')'
    )
  } else {
    return ensureResolveWithTimeout(
      window.zaraz.ecommerce(eventName, parametersWithDefaults)
    )
  }
}
